import React, { useState } from 'react';

import CodemirrorContainer from '../components/Codemirror/CodemirrorContainer';
import CodeRenderer from '../components/CodeRenderer/CodeRenderer';

const defaultCode = `function App () {
  const handleClick = () => {
    console.log('Hello world!')  
  }
  
  return (
    <div>
      I am a React component! Edit me and press Ctrl/Cmd + S.
      <br />
      <button onClick={handleClick}>
        Click to see a console log
      </button>
    </div>
  )
}`;

const HomePage = () => {
  const [code, setCode] = useState(defaultCode);

  return (
    <div>
      <div className="announcement">
        <h4>The React Handbook</h4>
        <p>
          Your practical code snippet guide to teach you how to use React in real world applications.
          <br />We start with basic knowledge and examples and get deeper into discovering hooks (yes, useEffect too!), routing, common use cases,
          component nesting, API calls - everything you could possibly encounter when coding an app of your own!
        </p>
        <p>
          You can take advantage of the code editing tool to render real React code on the go as you write. Choose a category on the left to see examples.
        </p>
      </div>
      <div className={'example-container grid'}>
        <CodemirrorContainer value={code} onChange={setCode} />
        <div className="flex-column">
          <CodeRenderer code={code} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
