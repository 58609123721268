import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EXAMPLES } from '../config/cases';

import CodeRenderer from '../components/CodeRenderer/CodeRenderer';
import CodemirrorContainer from '../components/Codemirror/CodemirrorContainer';
import GridToggle from '../components/GridToggle/GridToggle';

const ExamplePage = ({ match }) => {
  const { category, slug } = match.params;
  const example = useMemo(() => {
    return EXAMPLES.find(e => e.category.toLowerCase() === category && e.slug === slug);
  }, [category, slug]);

  const [code, setCode] = useState(example.code);
  const [gridMode, setGridMode] = useState('grid');

  useEffect(() => {
    setCode(example.code);
  }, [example]);

  return (
    <>
      <div className="breadcrumb">
        <GridToggle mode={gridMode} onChange={setGridMode} />
      </div>
      {gridMode === 'list' && example.description && <div className="alert">{example.description}</div>}
      <div className={`example-container ${gridMode}`}>
        <CodemirrorContainer value={code} onChange={setCode} />
        <div className="flex-column text-gray-900 text-sm">
          {gridMode === 'grid' && example.description && <div className="alert">{example.description}</div>}
          <CodeRenderer code={code} />
        </div>
      </div>
    </>
  );
};

ExamplePage.propTypes = {
  match: PropTypes.object.isRequired
};

export default ExamplePage;
