import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { CATEGORY_LABEL } from '../../config/cases';

const NavigationItems = ({ cases, search, onClick }) => {
  return (
    <div onClick={onClick}>
      {cases.map(categoryData => (
        <ul key={categoryData.category}>
          <li>
              <span className="aside-category">
                {CATEGORY_LABEL[categoryData.category]}
              </span>
            <ul>
              {categoryData.examples.map(example => {
                let link = `/${categoryData.category}/${example.slug}`;
                if (search) link += `?search=${search}`;

                return (
                  <li key={example.title}>
                    <NavLink to={link} activeClassName="active">
                      {example.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </li>
        </ul>
      ))}
    </div>
  );
};

NavigationItems.propTypes = {
  cases: PropTypes.array.isRequired,
  search: PropTypes.string,
  onClick: PropTypes.func
};

export default memo(NavigationItems);
