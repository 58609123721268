import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import HomePage from './views/HomePage';
import ExamplePage from './views/ExamplePage';

import Navigation from './components/Navigation/Navigation';
import ConsoleLogger from './components/ConsoleLogger/ConsoleLogger';
import NavigationToggle from './components/Navigation/NavigationToggle';

const App = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="app">
      <Router>
        <NavigationToggle onOpen={setIsNavOpen} />
        <Navigation isOpen={isNavOpen} onOpen={setIsNavOpen} />
        <div className="content py-4 px-4 lg:px-8 lg:px-16 overflow-y-auto flex flex-col flex-1">
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/:category/:slug" component={ExamplePage} />
          </Switch>
        </div>
      </Router>
      <ConsoleLogger />
    </div>
  );
};

export default App;
