import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'react-feather';

const NavigationToggle = ({ onOpen }) => {
  const handleOpen = () => {
    onOpen(true);
  };

  return (
      <button onClick={handleOpen} className="toggle aside-open">
        <Menu />
      </button>
  );
};

NavigationToggle.propTypes = {
  onOpen: PropTypes.func.isRequired
};

export default NavigationToggle;
