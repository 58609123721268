import React from 'react';
import PropTypes from 'prop-types';

class CodeBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.children !== this.props.children) {
      if (prevState.hasError) this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h4>Couldn&apos;t render your React component.</h4>;
    }

    return this.props.children;
  }
}

CodeBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default CodeBoundary;
