import React from 'react';
import { Grid, List } from 'react-feather';
import PropTypes from 'prop-types';

const options = [
  {
    mode: 'grid',
    Icon: Grid
  },
  {
    mode: 'list',
    Icon: List
  }
];

const GridToggle = ({ mode, onChange }) => {
  return (
    <div className="grid-toggle">
      {options.map(option => {
        const Icon = option.Icon;
        return (
          <button
            className={mode === option.mode ? 'active' : ''}
            key={option.mode}
            onClick={() => onChange(option.mode)}
          >
            <Icon />
          </button>
        );
      })}
    </div>
  );
};

GridToggle.propTypes = {
  mode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default GridToggle;
