const CATEGORY = {
  basics: 'basics',
  lists: 'lists',
  tables: 'tables',
  state: 'state',
  lifecycle: 'lifecycle',
  'memoized-callback': 'memoized-callback',
  routing: 'routing',
  form: 'form',
  'data-fetching': 'data-fetching',
  computed: 'computed',
  context: 'context',
  'custom-hooks': 'custom-hooks',
  'user-events': 'user-events',
  'refs': 'refs',
  navigation: 'navigation'
};

export const CATEGORY_LABEL = {
  basics: 'Basics',
  lists: 'Lists',
  state: 'State',
  lifecycle: 'Lifecycle & variable watching',
  'memoized-callback': 'Memoized callback / useCallback',
  routing: 'Routing & History',
  form: 'Form handling',
  'data-fetchinReact.Fragmentg': 'Data Fetching',
  tables: 'Tables',
  computed: 'Computed/Memoized variables',
  context: 'Context',
  'custom-hooks': 'Custom hooks',
  'user-events': 'User events',
  'refs': 'Refs',
  'navigation': 'Navigation components'
};

// snippets
const CODE_CARD = `function Card ({ title, content }) {
    return (
      <div className="card">
        <div className="card-title">{title}</div>
        <div className="card-content">
          {content}
        </div>
    </div>
  )
}`;

const CODE_CARD_CHILDREN = `function Card ({ title, children }) {
  return (
    <div className="card">
      <div className="card-title">{title}</div>
      <div className="card-content">
        {children}
      </div>
    </div>
  )
}`;

const CODE_USERS = `const users = [
  {
    name: "Mighty dragon",
    email: "dragon@cave.elsewhere"
  },
  {
    name: "Abducted Princess",
    email: "princess@castle.far"
  },
  {
    name: "Brave Knight",
    email: "knight@castle.far"
  },
  {
    name: "Little Elf",
    email: "elf@village.green"
  },
  {
    name: "Rainbow Uniciorn",
    email: "unicorn@clouds"
  }
]`;

export const EXAMPLES = [
  {
    category: CATEGORY.basics,
    title: 'Component',
    slug: 'component',
    description: 'A component must always have one root element in the return method. The HTML-like syntax in your component\'s return is called JSX.',
    code: `// App is a component displaying another component
function App () {
  return (
    <MyComponent />
  )
}

function MyComponent () {
    // component body
    
    // rendered elements
    return (
      <div>Hello, I'm a component!</div>
    )
}`
  },
  {
    category: CATEGORY.basics,
    title: 'Attaching CSS classes',
    slug: 'css-classes',
    description: 'Use the "className" keyword to bind your JSX to css classes.',
    code: `function App () {
  return (
    <div className="card">
      <div className="card-content">
        Pre-styled card by using css class names
      </div>
    </div>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Variables in JSX',
    slug: 'jsx-variables',
    description: 'Display variable or execute code in JSX using a {} bracket.',
    code: `function App () {
  const today = new Date().toLocaleString()
  
  return (
    <div>
      Current date: {today}
      <br />
      Also current date: {new Date().toLocaleString()}
    </div>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Props',
    slug: 'props',
    description: 'Props serve to pass variables to child components. Anything can be a prop - a string, a function or even a component! A component re-renders if its props change, its state changes or its parent re-renders.',
    code: `function App () {
  return (
    <Card
      title="Lorem ipsum"
      content="Some content" />
  )
}

${CODE_CARD}`
  },
  {
    category: CATEGORY.basics,
    title: 'Children prop',
    slug: 'props-children',
    description: 'Components can use the special "children" prop to pass children elements directly into their output.',
    code: `function App () {
  return (
    <Card title="Lorem ipsum">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac feugiat dui. Nunc elit purus, lobortis et hendrerit sed, hendrerit in purus. Suspendisse et sollicitudin massa. Sed non lacus facilisis, sollicitudin libero at, imperdiet ante. Aliquam efficitur justo tincidunt ligula gravida feugiat.
    </Card>
  )
}

${CODE_CARD_CHILDREN}`
  },
  {
    category: CATEGORY.basics,
    title: 'Component as a prop',
    slug: 'props-component',
    description: 'If you want to pass a component as a prop, render it like any other variable using the {} brackets.',
    code: `function App () {
  return (
    <Card title="Lorem ipsum" action={<CustomButton />}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ac feugiat dui. Nunc elit purus, lobortis et hendrerit sed, hendrerit in purus. Suspendisse et sollicitudin massa. Sed non lacus facilisis, sollicitudin libero at, imperdiet ante. Aliquam efficitur justo tincidunt ligula gravida feugiat.
    </Card>
  )
}

function CustomButton () {
  return (
    <button className="primary">Delete</button>
  )
}

function Card ({ title, children, action }) {
    return (
      <div className="card">
        <div className="card-title">{title}</div>
        <div className="card-content">
          {children}
        </div>
        <div className="card-actions">
          {action}
        </div>
    </div>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Conditional render',
    slug: 'conditional-render',
    description: '',
    code: `function App () {
  const isLoading = false // rewrite to true
  
  return (
    <div>
      This content is always shown
      <br />
      {isLoading ? <Loader /> : <UserCard />}
    </div>
  )
}

function Loader () {
  return (<React.Fragment>Loading...</React.Fragment>)
}

function UserCard () {
  return (
    <div className="card">
      <div className="card-content">
        Lorem ipsum
      </div>
    </div>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Conditional class name',
    slug: 'conditional-class-name',
    description: '',
    code: `function App () {
  const isPrimary = true // rewrite to false
  
  let combinedClass = 'other-class'
  if (isPrimary) combinedClass += ' primary'
  
  return (
    <React.Fragment>
      {/* use short if-else */}
      <button className={isPrimary ? 'primary' : ''}>
        Click me
      </button>
      {/* combined with other classes */}
      <button className={combinedClass}>
        Click me
      </button>
    </React.Fragment>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Fragment',
    slug: 'fragment',
    description: 'In order to provide more root elements and avoid creating additional markup (for example wrapping them in a div), use React.Fragment.',
    code: `function App () {
  return (
    <React.Fragment>
      <Card title="Title 1">
        Content 1
      </Card>
      <Card title="Title 2">
        Content 2
      </Card>
    </React.Fragment>
  )
}

${CODE_CARD_CHILDREN}`
  },
  {
    category: CATEGORY.basics,
    title: 'Binding handlers to elements',
    slug: 'binding-handlers',
    description: 'React\'s HTML elements have native props, such as onClick or onChange.',
    code: `function App () {
  const handleClick1 = () => {
    console.log('Mouse click 1')
  }
  
  const handleClick2 = () => {
    console.log('Mouse click 2')
  }
  
  const handleChange = () => {
    console.log('Input change')
  }

  return (
    <form>
      <button onClick={handleClick1} type="button">
        Click me 1
      </button>
      <button onClick={handleClick2} type="button">
        Click me 2
      </button>
      <input placeholder="Write something" onChange={handleChange}/>
    </form>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Parameters in handlers',
    slug: 'handlers-parameters',
    description: 'You can re-use a function and provide a parameter (or multiple ones) in the JSX code.',
    code: `function App () {
  const handleClick = (parameter) => {
    console.log('Mouse click', parameter)
  }

  return (
    <form>
      <button onClick={() => handleClick('button 1')} type="button">
        Click me 1
      </button>
      <button onClick={() => handleClick('button 2')} type="button">
        Click me 2
      </button>
      
      {/* WRONG - the function executes if you don't provide it as a return value */}
      <button onClick={handleClick('button 3')} type="button">
        Click me 3
      </button>
    </form>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Data defined in and outside of a component',
    slug: 'data-in-out',
    description: 'We always have the option to define data in a component or outside of it. Create methods outside of a component unless they\'re directly tied to its logic. In the case of functional components, everything gets re-created when the body re-renders. That can especially cause issues if you pass the data further down as props.',
    code: `// DO
const chartOptions = {
  type: 'line',
  height: 350 
}
    
function App () {
  // DON'T
  // const chartOptions = {
  //   type: 'line',
  //   height: 350
  // }
  
  return (
    <ChartComponent options={chartOptions} />
  )
}

function ChartComponent ({ options }) {
  return (
    <div className="some-chart" />
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'Reactivity',
    slug: 'reactivity',
    description: 'Not every variable declared in a component is reactive. A component reacts to a prop change and a state change. Changing a reactive value will trigger a re-render of the component and its children.',
    code: `function App () {
  const [count1, setCount1] = React.useState(1) 
  let count2 = 1
  
  const handleIncrement1 = () => {
    // we trigger a re-render by setting a new state
    setCount1(count1 + 1)
  }
  const handleIncrement2 = () => {
    // nothing happens!
    count2++
  }
  
  return (
    <div>
      Reactive count: {count1}
      <br />
      <button onClick={handleIncrement1}>
        Handle increment reactive count
      </button>
      <br />
      Static count: {count2}
      <br />
      <button onClick={handleIncrement2}>
        Handle increment static count
      </button>
    </div>
  )
}
`
  },
  {
    category: CATEGORY.basics,
    title: 'memo()',
    slug: 'memo',
    description: 'React.memo() is a higher component that serves a similar purpose as PureComponent - to prevent the wrapped component from re-rendering unless its props change. If your component re-renders, its children will re-render, too. That\'s why it\'s important to consider where using memo() makes sense.', // TODO
    code: `function ChildComponent1 () {
  console.log('I re-render even though I receive no props')
  
  return (
    <div>
      Re-rendering child component
    </div>
  )
}

function ChildComponent2 () {
  console.log('I will not re-render because I am wrapped in memo()')
  
  return (
    <div>
      Static child component
    </div>
  )
}

const MemoizedComponent = React.memo(ChildComponent2)

function App () {
  const [count1, setCount1] = React.useState(1) 
  
  const handleIncrement = () => {
    setCount1(count1 + 1)
  }
  
  return (
    <div>
      Reactive count: {count1}
      <br />
      <button onClick={handleIncrement}>
        Handle increment reactive count
      </button>
      <ChildComponent1 />
      <MemoizedComponent />
    </div>
  )
}
`
  },
  {
    category: CATEGORY.lists,
    title: 'Rendering a list/array',
    slug: 'rendering-array',
    description: 'We use the native Array.map() functionality to display data in a collection.',
    code: `const animals = ["Cat", "Dog", "Mouse"]

function App () {
  return (
    <ul>
      {animals.map(animal => {
        return <li>{animal}</li>
      })}
    </ul>
  )
}`
  },
  {
    category: CATEGORY.lists,
    title: 'List with keys',
    slug: 'rendering-list-keys',
    description: 'Include a key prop value (a unique identifier in an array) to ensure correct reactivity.',
    code: `const animals = ["Cat", "Dog", "Mouse"]

function App () {
  return (
    <ul>
      {animals.map(animal => (
        <li key={animal}>{animal}</li>
      ))}
    </ul>
  )
}`
  },
  {
    category: CATEGORY.lists,
    title: 'List with objects',
    slug: 'rendering-list-objects',
    description: 'We can loop arrays of objects just like we would loop arrays of strings.',
    code: `const teachers = [
  {
    name: "Mrs. Smith",
    subjects: "English, Physics"
  },
  {
    name: "Mr. Smith",
    subjects: "Arts, PE"
  }
]
  
function App () {
  return (
    <ul>
      {teachers.map(teacher => (
        <li key={teacher.name}>
          Name: <strong>{teacher.name}</strong>, 
          subjects: {teacher.subjects}
        </li>
      ))}
    </ul>
  )
}`
  },
  {
    category: CATEGORY.lists,
    title: 'Binding methods in lists',
    slug: 'lists-binding-methods',
    description: 'If you have a method you wish to dynamically bind to the elements in a list, use a short return function to provide the current iterated data as a parameter.',
    code: `const animals = ["Cat", "Dog", "Mouse", "Turtle", "Elephant", "Rhino"]

function App () {
  const handleLog = animalName => {
    console.log(animalName)  
  }
  
  return (
    <ul>
      {animals.map(animal => (
        <li key={animal}>
          <button onClick={() => handleLog(animal)}>
            Log {animal}
          </button>
        </li>
      ))}
    </ul>
  )
}`
  },
  {
    category: CATEGORY.routing,
    title: 'Routing setup',
    slug: 'routing-setup',
    description: 'Install the react-router-dom package to get routing for your application. We are using HashRouter for demo purposes, but you will find SPAs are often using BrowserRouter.',
    code: `import { HashRouter, Switch, Route, Link } from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <div className="nav">
       <Link to="/home">Home</Link>
       <Link to="/admin">Admin</Link>
     </div>
     
     <Switch>
       <Route
         path="/home"
         component={() => <div>Home page</div>} />
       <Route
         path="/admin"
         component={() => <div>Admin page</div>} />
     </Switch>
  </HashRouter>
  )
}`
  },
  {
    category: CATEGORY.routing,
    title: 'Access route parameters',
    slug: 'route-parameters',
    description: 'In real-life apps you will often find yourself using route parameters. In this example we will show how to retrieve the user\'s ID from the URL. We can also see that the UserPage component receives 3 props (history, match, location) due to being a Route\'s direct component.',
    code: `import { HashRouter, Switch, Route, Link } from 'react-router-dom'

function App () {

  return (
   <HashRouter>
     <div className="nav">
       <Link to="/home">
         Home
       </Link>
       <Link to="/user/1">
         User 1
       </Link>
       <Link to="/user/2">
         User 2
       </Link>
     </div>
     
     <Switch>
       <Route
         path="/home"
         component={() => <div>Home page</div>} />
       <Route
         path="/user/:id"
         component={UserPage} />
     </Switch>
  </HashRouter>
  )
}

function UserPage ({ match, history, location }) {
  return (
    <div>
      User {match.params.id}
    </div>
  )
}`
  },
  {
    category: CATEGORY.routing,
    title: 'Exact route',
    slug: 'exact-route',
    description: 'We use exact routes when a route subpath equals to another route\'s path. For example if we use the "/" as a path (Home), each subsequent path ("/admin") will contain our Home path "/". In order to match the Home component to the "/" route and nothing else, we provide the "exact" parameter.',
    code: `import { HashRouter, Switch, Route, Link } from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <div className="nav">
       <Link to="/">
         Home
       </Link>
       <Link to="/admin">
         Admin
       </Link>
     </div>
     
     <Switch>
       <Route
         path="/"
         component={() => <div>Home page</div>}
         exact />
       <Route
         path="/admin"
         component={() => <div>Admin page</div>} />
     </Switch>
  </HashRouter>
  )
}`
  },
  {
    category: CATEGORY.routing,
    title: 'Highlight active link',
    slug: 'highlight-link',
    description: 'Import the NavLink component from react-router-dom to get additional benefits, such as highlighting of the active link. Similarly like in Route, you need to include the "exact" prop to ensure correct highlighting in a sub-path.',
    code: `import { HashRouter, Switch, Route, NavLink } from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <div className="nav">
       <NavLink to="/" activeClassName="active" exact>
         Home
       </NavLink>
       <NavLink to="/admin" activeClassName="active">
         Admin
       </NavLink>
     </div>
     
     <Switch>
       <Route
         path="/"
         component={() => <div>Home page</div>}
         exact />
       <Route
         path="/admin"
         component={() => <div>Admin page</div>} />
     </Switch>
  </HashRouter>
  )
}`
  },
  {
    category: CATEGORY.routing,
    title: 'Programmatic navigation',
    slug: 'programmatic-navigation',
    description: 'We use react-router-dom\'s history object\'s push() method to nagivate the user programmatically. Use history.replace() if you wish to rewrite the current router entry, rather than creating a new one.',
    code: `import { HashRouter, Switch, Route } from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <Switch>
       <Route
         path="/"
         component={HomePage}
         exact />
       <Route
         path="/admin"
         component={() => <div>Admin page</div>} />
     </Switch>
  </HashRouter>
  )
}

function HomePage ({ history }) {
  const handleRedirect = () => {
    history.push('/admin')  
  }
  
  return (
    <button onClick={handleRedirect}>
      Click me to redirect
    </button>
  )
}`
  },
  {
    category: CATEGORY.routing,
    title: 'Redirect',
    slug: 'redirect',
    description: 'Use react-router-dom\'s Redirect component to provide automatic redirection.',
    code: `import { HashRouter, Switch, Route, Redirect } from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <Switch>
       <Route
         path="/home"
         component={() => <div>Home page</div>}
         exact />
       <Route
         path="/admin"
         component={() => <div>Admin page</div>} />
       <Redirect
         from="/"
         to="/home"
         exact />
     </Switch>
   </HashRouter>
  )
}
`
  },
  {
    category: CATEGORY.routing,
    title: 'Fallback to Not Found page',
    slug: '404-fallback',
    description: 'In this case, we want to redirect the user from the "/" url to "/home" (using "exact" parameter on the Redirect) and also redirect to Not Found page if the user provides an unspecified path.',
    code: `import {
  HashRouter,
  Switch,
  Route,
  Redirect,
  NavLink
} from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <Navigation />
     <Switch>
       <Route
         path="/home"
         component={() => <div>Home page</div>}
         exact />
       <Route
         path="/admin"
         component={() => <div>Admin page</div>} />
       <Route
         path="/not-found"
         component={() => <div>Page not found</div>} />
       <Redirect
         from="/"
         to="/home"
         exact />
       <Redirect
         from="*"
         to="/not-found" />
     </Switch>
   </HashRouter>
  )
}

function Navigation () {
  return (
    <div className="nav">
     <NavLink to="/" activeClassName="active" exact>
       Home
     </NavLink>
     <NavLink to="/admin" activeClassName="active">
       Admin
     </NavLink>
     <NavLink to="/dead-link" activeClassName="active">
       Dead link
     </NavLink>
    </div>
  )
}
`
  },
  {
    category: CATEGORY.routing,
    title: 'Access routing anywhere / useHistory()',
    slug: 'routing-anywhere',
    description: 'You receive the Route\'s props in direct route components. However, sometimes you\'re interested in those objects (history, location, match) further down the tree.',
    code: `import {
  HashRouter,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <Switch>
       <Route
         path="/"
         component={Homepage}
         exact />
       <Route
         path="/admin"
         component={() => <div>Admin page</div>} />
       <Redirect
         from="*"
         to="" />
     </Switch>
   </HashRouter>
  )
}

function Homepage ({ history, location, match }) {
  // Homepage has the props from its "Route" parent.
  return (
    <RedirectButton />
  )
}

function RedirectButton () {
  // react-router-dom provides us with useHistory(), useLocation() and useRouteMatch() hooks
  const history = useHistory()
  
  const handleRedirect = () => {
    history.push('/admin')
  }
  
  return (
    <button onClick={handleRedirect}>
      Click me to redirect
    </button>
  )
}
`
  },
  {
    category: CATEGORY.routing,
    title: 'Use query params in a route',
    slug: 'add-query-params',
    description: 'Use location.search property to get your current query string. There are native JS solutions to work with those, such as the URLSearchParams object. You can use URLSearchParams for both setting and getting properties in a query string. If your component has access to location (a Route\'s child or using the useLocation() hook), query string changes trigger a re-render.',
    code: `import {
  HashRouter,
  Switch,
  Route
} from 'react-router-dom'
  
function App () {
  return (
   <HashRouter>
     <Switch>
       <Route
         path="/"
         component={Homepage}
         exact />
       <Route
         path="/users"
         component={Users} />
     </Switch>
   </HashRouter>
  )
}

function Homepage ({ history }) {
  const handleManualRedirect = () => {
    history.push('users?name=princess&email=castle@kingdom.far')
  }
  
  const handleSearchParamsRedirect = () => {
    const searchParams = new URLSearchParams()
    searchParams.set('name', 'princess')
    searchParams.set('email', 'castle@kingdom.far')
    // search params will escape the @ character for us
    history.push('users?' + searchParams.toString())
  }
  
  return (
    <React.Fragment>
      <button onClick={handleManualRedirect}>
        Click to redirect with manual query string
      </button>
       <button onClick={handleSearchParamsRedirect}>
        Click to redirect with search params
      </button>
    </React.Fragment>
  )
}

function Users ({ location }) {
  const searchParams = new URLSearchParams(location.search)
  const name = searchParams.get('name')
  const email = searchParams.get('email')
  console.log(location.search)
  
  return (
    <div>
      {name}: {email}
    </div>
  )
}
`
  },
  {
    category: CATEGORY.state,
    title: 'State management',
    slug: 'state-management',
    description: 'The React.useState() hook lets us take the advantage of state in functional components. The value provided in the brackets is the default value of the state.',
    code: `function App () {
  const [counter, setCounter] = React.useState(0)
  
  function handleIncrement () {
    setCounter(counter + 1)
  }

  return (
    <div>
      Counter value: {counter}
      <br />
      <button onClick={handleIncrement}>
        Increment
      </button>
    </div>
  )
}`
  },
  {
    category: CATEGORY.state,
    title: 'Working with previous state',
    slug: 'previous-state',
    description: 'It\'s encouraged to access the previous state from within the parameter of the setState function to assure that you are working with the most recent state value.',
    code: `function App () {
  const [counter, setCounter] = React.useState(0)
  
  function handleIncrement () {
    setCounter(prevState => prevState + 1)
  }

  return (
    <div>
      Counter value: {counter}
      <br />
      <button onClick={handleIncrement}>
        Increment
      </button>
    </div>
  )
}`
  },
  {
    category: CATEGORY.state,
    title: 'Creating a classic toggle',
    slug: 'state-create-toggle',
    description: 'Setting a state and highlighting a selected button based on said state - an example you will face often when developing your applications!',
    code: `function App () {
  const [mode, setMode] = React.useState('grid')

  return (
    <div>
      Pick a mode: <br />
      <button
        onClick={() => setMode('grid')}
        className={mode === 'grid' ? 'primary' : ''}
      >
        Grid
      </button>
      <button
        onClick={() => setMode('list')}
        className={mode === 'list' ? 'primary' : ''}
      >
        List
      </button>
    </div>
  )
}`
  },
  {
    category: CATEGORY['memoized-callback'],
    title: 'useCallback introduction',
    slug: 'usecallback-introduction',
    description: 'React.useCallback() lets us memoize functions, which will prevent them from re-creating. You\'ll use memoized functions for passing them down as props or using them as dependencies for other useCallback or useEffect calls.',
    code: `function App () {
  // doesn't re-create when component re-renders
  const handleClick = React.useCallback(() => {
    console.log('I have been clicked')
  }, [])
  
  return (
    <button onClick={handleClick}>
      Click me
    </button>
  )
}
`
  },
  {
    category: CATEGORY['memoized-callback'],
    title: 'Memoized function as a prop',
    slug: 'usecallback-prop',
    description: 'You pass a memoized function to a child component wrapped in memo() to prevent it from re-rendering because it would be receiving a function that gets re-created otherwise. We update the App\'s state without triggering re-render on its child, ButtonComponent.',
    code: `function ButtonComponent ({ onClick }) {
  console.log('Button re-render')
  
  return (
    <button onClick={onClick}>
      Log click
    </button>
  )
}

const MemoizedButtonComponent = React.memo(ButtonComponent)

function App () {
  const [date, setDate] = React.useState(new Date())
  
  const handleUpdateDate = () => {
    setDate(new Date())
  }
  
  const handleClick = React.useCallback(() => {
    console.log('I have been clicked')
  }, [])
  
  return (
    <div>
      {date.toLocaleString()}
      <br />
      <button onClick={handleUpdateDate}>
        Click to update date
      </button>
      <br />
      <MemoizedButtonComponent onClick={handleClick} />
    </div>
  )
}
`
  },
  {
    category: CATEGORY['memoized-callback'],
    title: 'useCallback with parameters',
    slug: 'usecallback-parameters',
    description: 'If you\'re working with a variable in a memoized function, don\'t forget to include it as a dependency. Forgetting to do so and leaving the dependency array empty will result in the function not being re-created and the value won\'t update.',
    code: `function App () {
  const [count, setCount] = React.useState(1) 
  const handleIncrement = () => {
    setCount(previous => previous + 1)
  }
  
  return (
    <div>
      Count: {count}
      <br />
      <button onClick={handleIncrement}>
        Increment counter
      </button>
      <ButtonComponent count={count} />
    </div>
  )
}

function ButtonComponent ({ count }) {
  // re-creates when count changes
  const handleClickWithParams = React.useCallback(() => {
    console.log('Count is:', count)
  }, [count])
  
  // doesn't re-create and keeps the first value
  const handleClickWithoutParams = React.useCallback(() => {
    console.log('Wrong count is:', count)
  }, [])
  
  return (
    <div>
      <button onClick={handleClickWithParams} className="primary">
        Log current count
      </button>
      <button onClick={handleClickWithoutParams}>
        Log wrong count
      </button>
    </div>
  )
}`
  },
  {
    category: CATEGORY.lifecycle,
    title: 'Mount & Unmount with useEffect',
    slug: 'mount-unmount',
    description: 'Use React.useEffect() hook for both mount and unmount desired functionality implementation. If you leave the dependency array empty, that means the code inside will run only once, which replaces the class mount & unmount lifecycle hooks.',
    code: `function App () {
  console.log('Component rendering')
  
  React.useEffect(() => {
    console.log('Component mounted')
    
    return () => {
      console.log('Component unmounted')
    }
  }, [])
  
  return (
    <div />
  )
}`
  },
  {
    category: CATEGORY.lifecycle,
    title: 'Watch for variable change',
    slug: 'watch-variable',
    description: 'React.useEffect() also serves as a watcher for variable changes. If you include a variable in the dependency array, the body of the hook will run every time the variable changes.',
    code: `function App () {
  const [counter, setCounter] = React.useState(0)
  
  React.useEffect(() => {
    console.log('Counter value has been updated to:', counter)
  }, [counter])
  
  const handleIncrement = () => {
     setCounter(prev => prev + 1)
  }
  
  return (
    <div>
      {counter}
      <br />
      <button onClick={handleIncrement}>
        Increment
      </button>
    </div>
  )
}`
  },
  {
    category: CATEGORY.lifecycle,
    title: 'Fetch data on mount',
    slug: 'fetch-mount',
    description: 'This is how you would fetch & store data from your API on component mount.',
    code: `function App () {
  const [posts, setPosts] = React.useState(null)
  
  React.useEffect(() => {
    async function fetchData () {
      const data = await fetch('https://jsonplaceholder.typicode.com/posts')
      const response = await data.json()
      setPosts(response.slice(0, 10))
    }
    fetchData()
  }, [])
  
  if (!posts) return <div>Loading...</div>
  
  return (
    <ul>
      {posts.map(post => <li key={post.id}>{post.title}</li>)}
    </ul>
  )
}`
  },
  {
    category: CATEGORY.lifecycle,
    title: 'Memoize function used in useEffect',
    slug: 'memoize-function',
    description: 'If you don\'t want to define the fetching function inside the useEffect(), you can define it in the body of the component. Don\'t forget to wrap it in a useCallback() though - if you didn\'t, you would have caused an infinite loop. This function will never re-create unless you provide dependencies in the useCallback() array.',
    code: `function App () {
  const [posts, setPosts] = React.useState(null)
  
  const fetchData = React.useCallback(async () => {
    const data = await fetch('https://jsonplaceholder.typicode.com/posts')
    const response = await data.json()
    setPosts(response.slice(0, 10))
  }, [])
  
  React.useEffect(() => {
    fetchData()
  }, [fetchData])
  
  if (!posts) return <div>Loading...</div>
  
  return (
    <ul>
      {posts.map(post => <li key={post.id}>{post.title}</li>)}
    </ul>
  )
}`
  },
  {
    category: CATEGORY.lifecycle,
    title: 'useEffect & useCallback with parameters',
    slug: 'use-callback-parameters',
    description: 'Sometimes we do want to re-create the function. That\'s the case where you\'re working with route parameters, for example. If the ID in the url changes, re-create the function and re-fetch the data.',
    code: `const {
  HashRouter,
  Switch,
  Route,
  Redirect
} = ReactRouterDOM

function App () {
  return (
   <HashRouter>
     <Navigation />
     
     <Switch>
       <Route
         path="/post/:id"
         component={PostPage} />
       <Redirect from="/" to="/post/1" />
     </Switch>
    </HashRouter>
  )
}

function PostPage ({ match }) {
  const [post, setPost] = React.useState(null)
  const postId = match.params.id
  
  const fetchPost = React.useCallback(async () => {
    const data = await fetch('https://jsonplaceholder.typicode.com/posts/' + postId)
    const response = await data.json()
    setPost(response)
  }, [postId]) // fetchPost gets re-created when post id changes
  
  React.useEffect(() => {
    setPost(null) // reset post on every page switch
    fetchPost()
  }, [fetchPost]) // useEffect gets called when fetchPost re-creates
  
  if (!post) return <div>Loading...</div>
  
  return (
    <div>
      <h4>{post.title}</h4>
      {post.body}
    </div>
  )
}

function Navigation () {
  const { NavLink } = ReactRouterDOM
  
  return (
    <div className="nav">
      <NavLink to="/post/1" activeClassName="active">Post 1</NavLink>
      <NavLink to="/post/2" activeClassName="active">Post 2</NavLink>
      <NavLink to="/post/3" activeClassName="active">Post 3</NavLink>
    </div>
  )
}`
  },
  {
    category: CATEGORY.computed,
    title: 'useMemo example',
    slug: 'use-memo-example',
    description: 'React.useMemo() serves as a memoized/computed value. It works with a dependency array and re-computes once a dependency changes. Its typical usages are filtered data or heavier calculations, which you don\'t want to run on each re-render and don\'t want to store in state, as that value is dynamic.',
    code: `function App () {
  const [animals] = React.useState([
    'dog', 'cat', 'mouse', 'elephant', 'wolf', 'peacock', 'fish', 'dragon', 'ant', 'dinosaur'
  ])
  
  const filteredAnimals = React.useMemo(() => {
    const result = []
    // we only wish to see the animals that have the "d" letter in them - feel free to edit the value!
    const filter = 'd'
    animals.forEach(animal => {
      if (animal.includes(filter)) {
        result.push(animal)
      }
    })
    return result
  }, [animals])
  
  return (
    <div>
      Filtered animals: {filteredAnimals.join(', ')}
    </div>
  )
}
`
  },
  {
    category: CATEGORY.computed,
    title: 'useMemo and form filtering',
    slug: 'use-memo-form',
    description: 'This is how you would useMemo() in a traditional case where you filter your stored data.',
    code: `function App () {
  const [animals] = React.useState([
    'dog', 'cat', 'mouse', 'elephant', 'wolf', 'peacock', 'fish', 'dragon', 'ant', 'dinosaur'
  ])
  const [filter, setFilter] = React.useState('')
  
  const filteredAnimals = React.useMemo(() => {
    const result = []
    animals.forEach(animal => {
      if (animal.includes(filter)) {
        result.push(animal)
      }
    })
    return result
  }, [animals, filter])
  
  return (
    <div>
      <FilterForm value={filter} onChange={setFilter} />
      <br />
      Filtered animals: {filteredAnimals.join(', ')}
    </div>
  )
}

function FilterForm ({ value, onChange }) {
  const handleChange = (event) => {
    onChange(event.target.value)
  }
  
  return (
    <form>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="Filtered value"
      />
    </form>
  )
}
`
  },
  {
    category: CATEGORY.context,
    title: 'useContext & createContext',
    slug: 'usecontext-createcontext',
    description: 'React\'s Context\'s purpose is to share data in a group of components, where you don\'t wish to use props to pass a variable several levels of components down. A change of consumed context\'s value also triggers a re-render.',
    code: `const defaultValue = 0
const counterContext = React.createContext(defaultValue)
// Provider is a context's component that accepts value as a prop 
const CounterProvider = counterContext.Provider

function App() {
  const [counter, setCounter] = React.useState(0)
  
  const handleIncrement = () => {
    setCounter(prev => prev + 1)
  }
  const handleDecrement = () => {
    setCounter(prev => prev - 1)
  }
  
  return (
    <CounterProvider value={counter}>
      <button onClick={handleIncrement}>
        Increment
      </button>
      <button onClick={handleDecrement}>
        Decrement
      </button>
      <CountRenderer />
    </CounterProvider>
  )
}

function CountRenderer () {
  // get the context's value by using useContext()
  // and passing the context as an argument
  const currentValue = React.useContext(counterContext)
  
  return (
    <div>Value: {currentValue}</div>
  )
}
`
  },
  {
    category: CATEGORY.refs,
    title: 'Focus an input programatically',
    slug: 'input-focus',
    description: 'React.useRef() provides access to DOM elements programatically. You can use them to focus an input after you render it conditionally, for example. The rendering phase needs to be finished in order to access ref\'s current property.',
    code: `function App() {
  const usernameInput = React.useRef(null);
  const emailInput = React.useRef(null);
  
  React.useEffect(() => {
    // focus username on mount, when rendering is finished
    usernameInput.current.focus()
  }, [])
  
  const handleFocusEmail = () => {
    emailInput.current.focus()
  }
  
  // we don't have the .current property in the rendering phase
  console.log(usernameInput.current);

  return (
    <div>
      <input type="text"
        placeholder="Username"
        ref={usernameInput}
      />
      <input type="text"
        placeholder="E-mail"
        ref={emailInput}
      />
      <button onClick={handleFocusEmail}>
        Focus e-mail
      </button>
    </div>
  )
}
`
  },
  {
    category: CATEGORY['user-events'],
    title: 'Form elements events',
    slug: 'onclick-onchange',
    description: 'The onChange and onClick handlers on an HTML element provide us with an event object as the default first parameter, from which we can retrieve the current value.',
    code: `function App () {
  const handleClick = (event) => {
    console.log('Mouse click event', event)
  }
  
  const handleChange = (event) => {
    console.log('Input change event', event)
    console.log('and value', event.target.value)
  }
  
  return (
    <form>
      <input
        onChange={handleChange}
        placeholder="Say something.."
      />
      <input
        // get the event object as a parameter
        onChange={(event) => handleChange(event)}
        placeholder="Say something else.."
      />
      <button onClick={handleClick} type="button">
        Click me
      </button>
    </form>
  )
}
`
  },
  {
    category: CATEGORY.form,
    title: 'Input reactivity',
    slug: 'input-reactivity',
    description: 'To achieve a controlled input, we need to provide its "value". Utilizing React\'s useState(), we can store and update the value as we change it.',
    code: `function App () {
  const [value, setValue] = React.useState('')
  
  const handleChange = event => {
    setValue(event.target.value)
  }

  return (
    <form>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="Dynamic value"
      />
    </form>
  )
}
`
  },
  {
    category: CATEGORY['user-events'],
    title: 'Form submitting',
    slug: 'form-submitting',
    description: 'The onSubmit handler on the form element will be triggered by clicking the submit button or pressing the Enter key inside a form input. Similarly like onChange prop, onSubmit will provide us the submit event when called. In order to stop the page from refreshing, we need to call its native method event.preventDefault().',
    code: `function App () {
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Submit without refresh!')
    // do something
  }

  return (
    <form onSubmit={handleSubmit}>
      <button type="submit">Submit</button>
    </form>
  )
}`
  },
  {
    category: CATEGORY.form,
    title: 'Whole form example',
    slug: 'form-example',
    description: 'We can keep & store the value of our whole form, re-using the handleChange method for each input.',
    code: `function App () {
  const [data, setData] = React.useState({
    username: '',
    email: '',
    password: ''
  })
  
  const handleChange = paramName => event => {
    setData(prevData => ({
      username: prevData.username,
      email: prevData.email,
      password: prevData.password,
      [paramName]: event.target.value
    }))
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Submit', data)
  }

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" placeholder="Username" value={data.username} onChange={handleChange('username')} />
      <input type="text" placeholder="E-mail" value={data.email} onChange={handleChange('email')} />
      <input type="password" placeholder="Password" value={data.password} onChange={handleChange('password')} />
      <button type="submit">Submit</button>
    </form>
  )
}`
  },
  {
    category: CATEGORY.form,
    title: 'Form validation',
    slug: 'form-validation',
    description: 'You can use your stored form state to write a custom validation rules by simply providing the disabled prop to the native HTML button.',
    code: `function App () {
  const [data, setData] = React.useState({
    email: '',
    password: ''
  })
  
  const handleChange = paramName => event => {
    setData(prevData => ({
      email: prevData.email,
      password: prevData.password,
      [paramName]: event.target.value
    }))
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    console.log('Submit', data)
  }
  
  const isDisabled = !data.email || !data.password

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" placeholder="E-mail" value={data.email} onChange={handleChange('email')} />
      <input type="password" placeholder="Password" value={data.password} onChange={handleChange('password')} />
      <button type="submit" disabled={isDisabled}>Submit</button>
    </form>
  )
}`
  },
  {
    category: CATEGORY.form,
    title: 'Custom multiselect',
    slug: 'custom-multiselect',
    description: 'You\'ll often find multiselect components in UI libraries.',
    code: `const options = ['cooking', 'piano', 'painting', 'design', 'football', 'crafting', 'sleeping']

function App () {
  const [skills, setSkills] = React.useState([])

  return (
    <div>
      My skills are: {skills.join(', ')}
      <br />
      <Multiselect
        options={options}
        selected={skills}
        onChange={setSkills}
      />
    </div>
  )
}

function Multiselect ({ options, selected, onChange }) {
  const [searched, setSearched] = React.useState('')
  const [isOpen, setIsOpen] = React.useState(false)
  
  const filteredOptions = React.useMemo(() => {
    return options.filter(option => option.includes(searched))
  }, [options, searched])
  
  const handleSearchedSet = e => {
    setSearched(e.target.value)
  }
  
  const handleSelect = (option) => {
    const isSelected = selected.includes(option)
    let newOptions
    
    if (isSelected) {
      // if selected, remove the clicked option
      newOptions = selected.filter(o => o !== option)
    } else {
      // else add it in our selected array
      newOptions = [...selected, option]
    }
    
    onChange(newOptions)
  }
  
  return (
    <div className="multiselect-container">
      {/* The backdrop is invisble, but closes the dropdown on click */}
      {isOpen && (
        <div className="multiselect-backdrop"
          onClick={() => setIsOpen(false)}
        />
      )}
      <input type="text"
        placeholder="Type to filter options"
        value={searched}
        onChange={handleSearchedSet}
        onFocus={() => setIsOpen(true)}
      />
      {isOpen && (
        <ul>
          {filteredOptions.map(option => {
            const isSelected = selected.includes(option)
            
            return (
              <li
                key={option}
                onClick={() => handleSelect(option)}
                className={isSelected ? 'active' : ''}
              >
                {option}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}
`
  },
  {
    category: CATEGORY.form,
    title: 'Input synchronized with localStorage',
    slug: 'input-localstorage',
    description: 'localStorage\'s data is saved across browser sessions. This way you can store user\'s token or preferred settings. sessionStorage works in a similar way, where the data is stored only during the lifetime of an opened browser tab.',
    code: `function App () {
  const storedValue = localStorage.getItem('username')
  const [value, setValue] = React.useState(storedValue || '')
  
  const handleChange = e => {
    const value = e.target.value
    setValue(value)
    localStorage.setItem('username', value)
  }

  return (
    <form>
      <label>Username</label>
      <input type="text" value={value} onChange={handleChange} />
    </form>
  )
}
`
  },
  {
    category: CATEGORY.tables,
    title: 'Table with data',
    slug: 'table-with-data',
    code: `${CODE_USERS}

function App () {
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>E-mail</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <tr key={user.email}>
            <td>{user.name}</td>
            <td>{user.email}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
`
  },
  {
    category: CATEGORY.tables,
    title: 'Table with data & filter',
    slug: 'table-with-data-filter',
    description: 'Combining what we learned with lists, state, forms and React.useMemo(), we can write a filter for our table.',
    code: `${CODE_USERS}

function App () {
  const [localUsers, setLocalUsers] = React.useState(users)
  const filterState = React.useState({ name: '', email: '' })
  const [filter] = filterState
  
  const filteredUsers = React.useMemo(() => {
    const result = []
    
    localUsers.forEach(user => {
      const fName = filter.name.toLowerCase()
      const fEmail = filter.email.toLowerCase()
      const includesUsername = user.name.toLowerCase().includes(fName)
      const includesEmail = user.email.toLowerCase().includes(fEmail)
      if (includesUsername && includesEmail) {
        result.push(user)
      }
    })
    
    return result
  }, [localUsers, filter])
  
  const handleRemove = email => {
    setLocalUsers(
      previous => previous.filter(user => user.email !== email)
    )
  }

  return (
    <div>
      <UserFilter filterState={filterState} />
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>E-mail</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.email}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>
                <button onClick={() => handleRemove(user.email)}>
                  X
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

function UserFilter ({ filterState }) {
  const [filter, setFilter] = filterState;
  
  const handleChange = paramName => event => {
    setFilter(previous => ({
      name: previous.name,
      email: previous.email,
      [paramName]: event.target.value
    }))
  }
  
  return (
    <form>
      <input
        type="text"
        value={filter.name}
        placeholder="Name"
        onChange={handleChange('name')} />
      <input
        type="text"
        value={filter.email}
        placeholder="E-mail"
        onChange={handleChange('email')} />
    </form>
  )
}
`
  },
  {
    category: CATEGORY['custom-hooks'],
    title: 'Hooks basics',
    slug: 'hooks-basics',
    description: 'Hooks serve to reuse logic in functional components. Their name always starts with the "use" word. They cannot be executed conditionally. The component which contains the hook treats the logic inside the hook as if it were its own, preserving reactivity.',
    code: `const useLifecycleLogs = () => {
  console.log('The component has triggered a re-render')

  React.useEffect(() => {
    console.log('The component has been mounted')
    
    return () => {
      console.log('The component has been unmounted')
    }
  }, [])
}

function App () {
  useLifecycleLogs()

  return (
    <div />
  )
}
`
  },
  {
    category: CATEGORY['custom-hooks'],
    title: 'Counter hook',
    slug: 'counter-hook',
    description: 'App will re-render each time a new count is set, receiving its reactivity from the useCounter() hook and its state change.',
    code: `const useCounter = () => {
  const [count, setCount] = React.useState(0)
  
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCount(previous => previous + 1)
    }, 1000)
    
    return () => {
      clearInterval(interval)
    }
  }, [])
  
  return count
}

function App () {
  const count = useCounter()

  return (
    <div>{count}</div>
  )
}
`
  },
  {
    category: CATEGORY['custom-hooks'],
    title: 'Reusable Modal window',
    slug: 'modal-window',
    description: 'A simple solution to approaching opening a Modal window from anywhere in your app - using state, context and a custom hook!',
    code: `// store the modal component in a context
const ModalContext = React.createContext(null)

/**
* Simplify the access to the ModalContext by creating a custom hook 
*/
const useSetModal = () => {
  const handleSetComponent = React.useContext(ModalContext)
  
  return handleSetComponent
}

/**
* App is responsible for storing the component we're displaying in a Modal window
* It then passes the setter function into the ModalContext
* We consume the setter function in the useSetModal() hook
*/
function App () {
  const [modalComponent, setModalComponent] = React.useState(null)

  return (
    <ModalContext.Provider value={setModalComponent}>
      <CustomButton />
      
      {modalComponent && (
        <Modal component={modalComponent} />
      )}
    </ModalContext.Provider>
  )
}

function CustomButton () {
  // With a custom hook, you can access the modal setting function from anywhere
  const handleSetModal = useSetModal()
  
  const handleOpenForm = () => {
    // pass the component you wish to render to the setter function
    handleSetModal(<CustomForm userId="123" />)
  }
  
   const handleOpenCard = () => {
    handleSetModal(
      <Card>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus semper enim vitae volutpat imperdiet. Vestibulum tempor rutrum justo sit amet convallis. Nulla facilisi. 
      </Card>
    )
  }
  
  return (
    <React.Fragment>
      <button onClick={handleOpenForm}>
        Open form
      </button>
      <button onClick={handleOpenCard}>
        Open card
      </button>
    </React.Fragment>
  )
}

function Modal ({ component }) {
  const handleSetModal = useSetModal()
  
  const handleClose = () => {
    // we wish to close the modal on click, it's enough to set the component to null
    handleSetModal(null)
  }
  
  const handleStop = e => {
    e.stopPropagation();
  }
  
  return (
    <div className="modal-backdrop" onClick={handleClose}>
      <div className="modal-body" onClick={handleStop}>
        {component}
        <button onClick={handleClose} className="primary">
          Close
        </button>
      </div>
    </div>
  )
}

function CustomForm ({ userId }) {
  return (
    <form>
      <label>User ID</label>
      <input type="text" defaultValue={userId} />
    </form>
  )
}

function Card ({ children }) {
  return (
    <div className="card">
      <div className="content">
        {children}
      </div>
    </div>
  )
}
`
  },
  {
    category: CATEGORY['user-events'],
    title: 'Stopping a click from propagating',
    slug: 'click-stop-propagation',
    description: 'If you want to close a modal window when you click its background, but wish to keep it open when we click inside it, use event.stopPropagation() to prevent the click from bubbling up to the parent element.',
    code: `function App () {
  const [showModal, setShowModal] = React.useState(false)
  
  const handleClick = () => {
    setShowModal(true)
  }
  
  return (
    <React.Fragment>
      <button onClick={handleClick}>
        Show modal
      </button>
      {showModal && <Modal onToggle={setShowModal} />}
    </React.Fragment>
  )
}

function Modal ({ onToggle }) {
  const handleClose = (event) => {
    onToggle(false)
  }
  
  // we have to stop the event propagation, so the event doesn't bubble up
  // try removing this method to see clicking inside div propagates into its parent
  const handleStop = (event) => {
    event.stopPropagation()
  }
  
  return (
    <div className="modal-backdrop" onClick={handleClose}>
      <div className="modal-body"  onClick={handleStop}>
        This is your modal window
        <br />
        <button className="primary">
          Click and keep the modal open
        </button>
      </div>
    </div>
  )
}
`
  },
  {
    category: CATEGORY['user-events'],
    title: 'Window event listener',
    slug: 'window-event-listener',
    description: 'You\'d use a resize event listener to programatically set whether a page is viewed on a mobile device, for example. You always have to remove the attached event listener in order to prevent leaking, which we do in the useEffect\'s return. Create a memoized function using useCallback and pass it as the event\'s handler. You can use any kind of listener this way.',
    code: `function App () {
  const [isMobile, setIsMobile] = React.useState(false)
  
  React.useEffect(() => {
    window.addEventListener('resize', handleResize, true);
    
    return () => {
      window.removeEventListener('resize', handleResize, true);
    }
  }, [])
  
  const handleResize = React.useCallback((event) => {
    // you can use window's width to recognize a mobile device width
    // you can also use its height to see whether the mode is landscape or portrait
    const width = event.currentTarget.innerWidth
    console.log('Window width:', width)
    setIsMobile(width <= 1024)
  }, [])
  
  return (
    <React.Fragment>
      Resize me to see a console log!
    </React.Fragment>
  )
}
`
  },
  {
    category: CATEGORY.navigation,
    title: 'Opening menu',
    slug: 'opening-menu',
    description: 'Sometimes you wish to toggle a menu programmatically (from the side or top of the page) for mobile devices. The transition is achieved by css, which is written inline for demo purposes.',
    code: `import { HashRouter, Switch, Route, Link } from 'react-router-dom'
  
function App () {
  const [isOpen, setIsOpen] = React.useState(false);
  
  const handleOpen = () => {
    setIsOpen(true);
  }
  
   const handleClose = () => {
    setIsOpen(false);
  }
  
  return (
   <HashRouter>
     <button onClick={handleOpen}>
      Open menu
     </button>
     <MenuItems isOpen={isOpen} onClose={handleClose} />
     
     <Routes />
  </HashRouter>
  )
}

function MenuItems ({ isOpen, onClose }) {
  const handleClick = (event) => {
    event.stopPropagation();
    onClose();
  }
  
  // since we're opening menu from the right side,
  // we want to push it out of the viewport to hide it
  // if we were opening it from the left side, we would use -100%
  let translate = isOpen ? '0' : '100%';
  let style = {
    transition: '0.2s',
    transform: 'translateX(' + translate + ')',
    position: 'fixed',
    top: '0',
    right: '0'
  };
  
  return (
    <div className="aside-nav" style={style} onClick={handleClick}>
      <Link to="/">
        Home
      </Link>
      <Link to="/photos">
        Photos
      </Link>
      <Link to="/blog">
        Blog
      </Link>
    </div>
  )
}

function Routes () {
  return (
    <Switch>
       <Route
         path="/"
         component={() => <div>Home page</div>}
         exact />
       <Route
         path="/photos"
         component={() => <div>Photos page</div>} />
       <Route
         path="/blog"
         component={() => <div>Blog page</div>} />
     </Switch>
  )
}
`
  },
  {
    category: CATEGORY.navigation,
    title: 'Tabs',
    slug: 'tabs',
    description: '',
    code: `const options = [
  {
    label: 'Home',
    slug: 'home',
    content: (<h2>Home Page</h2>)
  },
  {
    label: 'Photos',
    slug: 'photos',
    disabled: true,
    content: (<h2>Photos Page</h2>)
  },
  {
    label: 'Blog',
    slug: 'blog',
    content: (<h2>Blog Page</h2>)
  }
]

function App () {
  return (
    <Tabs options={options} selected="home" />
  )
}

function Tabs ({ options, selected }) {
  const defaultSelected = selected || options[0].slug
  const [selectedTabName, setSelectedTabName] = React.useState(defaultSelected)
  
  const selectedTab = React.useMemo(() => {
    return options.find(o => o.slug === selectedTabName)
  }, [options, selectedTabName])
  
  return (
    <div className="tabs-container">
      <TabsOptions options={options} selected={selectedTabName} onClick={setSelectedTabName} />
      {selectedTab.content}
    </div>
  )
}

function TabsOptions ({ options, selected, onClick }) {
  return (
    <div className="tabs-options">
      {options.map(option => (
        <TabButton option={option} selected={selected} onClick={onClick} key={option.slug} />
      ))}
    </div>
  )
}

function TabButton ({ option, selected, onClick }) {
  const handleClick = () => {
    if (!option.disabled) {
      onClick(option.slug)
    }
  }
  
  const classes = ['tab-button']
  if (option.disabled) {
    classes.push('disabled')
  }
  else if (selected === option.slug) {
    classes.push('selected')
  }
  
  return (
    <button onClick={handleClick} className={classes.join(' ')}>
      {option.label}
    </button>
  )
}
`
  },
];

export const getGroupedExamples = () => {
  const result = {};

  EXAMPLES.forEach(example => {
    const { category, ...caseProps } = example;
    if (!result[category]) result[category] = [caseProps];
    else result[category].push(caseProps);
  });

  const arrayResult = [];

  Object.keys(result).forEach(category => {
    arrayResult.push({
      category,
      examples: result[category]
    });
  });

  return arrayResult;
};
