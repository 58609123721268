import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { X } from 'react-feather';

import { getGroupedExamples } from '../../config/cases';

import NavigationItems from './NavigationItems';

const cases = getGroupedExamples();

const Navigation = ({ isOpen, onOpen }) => {
  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const search = params.get('search') || '';

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    let finalUrl = location.pathname;
    if (value) finalUrl += `?search=${value}`;
    history.push(finalUrl);
  };

  const filteredCases = useMemo(() => {
    if (!search) return cases;

    const result = JSON.parse(JSON.stringify(cases));
    const searched = search.toLowerCase();

    result.forEach(category => {
      category.examples = category.examples.filter(example => {
        const title = (example.title || '').toLowerCase();
        const description = (example.description || '').toLowerCase();
        const code = (example.code || '').toLowerCase();
        return title.includes(searched) || description.includes(searched) || code.includes(searched);
      });
    });
    return result;
  }, [search]);

  const handleClose = () => {
    onOpen(false);
  };

  return (
    <div className={`aside ${isOpen ? 'open' : ''}`}>
      <button onClick={handleClose} className="toggle aside-close">
        <X />
      </button>
      <input type="text"
        placeholder="Search a keyword"
        value={search}
        onChange={handleChangeSearch}
      />
      <br />
      <NavigationItems cases={filteredCases} search={search} onClick={handleClose} />
    </div>
  );
};

Navigation.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func
};

export default Navigation;
