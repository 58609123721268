import React, { useEffect, useState } from 'react';

const handleSetLogs = (onSetLogs, logText, type = 'log') => {
  const timestamp = Date.now();

  const logData = {
    body: logText,
    type,
    timestamp
  };

  setTimeout(() => {
    onSetLogs(previous => {
      return [...previous, logData];
    });
  }, 0);
  setTimeout(() => {
    onSetLogs(previous => {
      return previous.filter(log => log.timestamp !== timestamp);
    });
  }, 6000);
};

const handleSetup = (onSetLogs) => {
  // don't initialize circular reference
  if (console.stdlog || console.stderror) return;

  console.stdlog = console.log.bind(console);
  console.log = function () {
    const newLog = Array.from(arguments);

    handleSetLogs(onSetLogs, newLog);

    console.stdlog.apply(console, arguments);
  };

  console.stderror = console.error.bind(console);
  console.error = function () {
    const newLog = Array.from(arguments);

    handleSetLogs(onSetLogs, newLog, 'error');

    console.stderror.apply(console, arguments);
  };
};

const handleCleanup = () => {
  console.log = console.stdlog.bind(console);
  console.error = console.stderror.bind(console);

  console.stdlog = null;
  console.stderror = null;
};

const ConsoleLogger = () => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    handleSetup(setLogs);

    return () => {
      handleCleanup();
    };
  }, [setLogs]);

  return (
    <div className="console-container">
      <ul>
        {logs.map((log, index) => (
          <li key={log.timestamp + index} className={log.type}>
            {log.body.map((fragment, index) => (
              <span key={fragment + index}>
                {getConsoleText(fragment) + ' '}
              </span>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};

const getConsoleText = fragment => {
  if (typeof fragment === 'string' || typeof fragment === 'number') {
    return fragment;
  }

  try {
    return JSON.stringify(fragment);
  } catch (_) {
    return '{ ... }';
  }
};

export default ConsoleLogger;
