import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Codemirror from './Codemirror';

const CodemirrorContainer = ({ value, onChange }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (localValue !== value) setLocalValue(value);
  }, [value]);

  const handleChange = e => {
    const value = e.target.value;
    setLocalValue(value);
  };

  const handleSave = () => {
    onChange(localValue);
  };

  const handleKeydown = useCallback((event) => {
    if ((event.metaKey || event.ctrlKey) && (event.key === 's' || event.keyCode === 83)) {
      event.preventDefault();
      handleSave();
    }
  }, [localValue]);

  return (
    <div className="codemirror-container" onKeyDown={handleKeydown}>
      <Codemirror
        onChange={handleChange}
        value={localValue}
        options={{ mode: 'text/jsx' }}
      />
      {value !== localValue && (
        <div className="codemirror-unsaved">
          <button onClick={handleSave}>Save</button>
        </div>
      )}
    </div>
  );
};

CodemirrorContainer.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CodemirrorContainer;
