import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.jsx';
import reportWebVitals from './reportWebVitals';

import './assets/css/codemirror.scss';
import './assets/css/index.scss';

(async function init() {
  await import('codemirror/mode/xml/xml');
  await import('codemirror/mode/javascript/javascript');
  await import('codemirror/mode/jsx/jsx');
  await import('codemirror/mode/htmlmixed/htmlmixed');

  ReactDOM.render(
    <App />,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
