import React, { useState, useEffect, memo, useMemo } from 'react';
import * as ReactRouterDOM from 'react-router-dom';
import { transform } from 'babel-standalone';
import PropTypes from 'prop-types';

import CodeBoundary from './CodeBoundary';

const CodeRenderer = ({ code }) => {
  const [workingCode, setWorkingCode] = useState('');

  useEffect(() => {
    let cleanedCode = code.replace('import', 'const');
    cleanedCode = cleanedCode.replace('from \'react-router-dom\'', '= ReactRouterDOM');

    try {
      const babelCode = transform(cleanedCode, { presets: ['react'] }).code;
      if (workingCode !== babelCode) setWorkingCode(babelCode);
    } catch(_) {
      //
    }
  }, [code, workingCode]);

  const returnedComponent = useMemo(() => {
    if (!workingCode) return null;

    let componentCode = workingCode.replace('"use strict";', '').trim();
    componentCode = componentCode.replace('function App()', 'return function App()');
    componentCode = componentCode.replace('import', 'const)');
    componentCode = componentCode.replace('from \'react-router-dom', '= ReactRouterDOM');

    const func = new Function('React', 'ReactRouterDOM', `${componentCode}`);
    const ResultComponent = func(React, ReactRouterDOM);
    return <ResultComponent />;
  }, [workingCode]);

  if (!returnedComponent) return null;

  return (
    <CodeBoundary>
      {returnedComponent}
    </CodeBoundary>
  );
};

CodeRenderer.propTypes = {
  code: PropTypes.string.isRequired
};

export default memo(CodeRenderer);
